.card-hover {
  font-family: Tahoma, Geneva, sans-serif;
  text-transform: none !important;
  color: black !important;
  width: 100%;
  height: 100%;
  background-color: white !important;
  background-size: 300%;
  background-image: linear-gradient(45deg, white, lavender, white);
  background-repeat: no-repeat;
  background-position: 200%;
  transition: background-position 0.75s ease-in-out !important;
}

.card-hover:hover {
  background-position: 0;
}