.about-heading{
    text-align: center;
    margin-top: 10px;
}
.member{
    max-width: 300px;
    height: 300px;
    display: inline-block;
    text-align: center;
    padding: 15px;
}

.member img{
    width: 200px;
}

.team{
    width: 100%;
    text-align: center;
    
}

.director{
    text-align: center;
    width: 100%;
}

.subTeam-3{
    float: left;
    width: 48%;
    margin: 0 auto;
    padding: 5px;
    text-align: center;
}

.subTeam-2{
    float: left;
    width: 32%;
    padding: 5px;
    margin: 0 auto;
    text-align: center;
}


