.header {
    width: 100%;
    height: 300px;
    overflow: hidden;
    padding: 2.5rem;
    background-color: lavender; /*#f7f0ff;*/
    margin: 0 0 4rem 0;
}

.header img {
    height: 100%;
    width: auto;
    display: inline-block;
    margin: 0 1.5rem;
}

.header .title {
    display: inline-block;
    width: 30%;
    vertical-align: bottom;
}

.header h1 {
    font-weight: 300;
    font-size: 38px;
}

.failure-story-profile{
    width: 100%;
    height: 250px;
    background-color: lavender;
    overflow: hidden;
}

.failure-story-profile img {
    height: 100%;
    width: 70%;
    margin: 0.5rem 0 0 3.5rem;
}

.failure-story-profile .bio {
    vertical-align: text-bottom;
    margin: 2.5rem 2.25rem 0 0;
}

.post {
    width: 85%;
    padding: 2.5rem;
    margin: 2.5rem auto;
    font-family: 'Open Sans', sans-serif;
}

.post p {
    font-weight: 400;
    font-size: 3vh;
    margin: 1.5rem 0;
    text-indent: 8vh;
    background-color: transparent;
}