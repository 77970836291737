
.hero-text{
    text-align: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform:translate(-50%,-50%);
    color: white;
}


.hero-text row{
    text-align: center;
}

.Title-image{
background-image:url('../img/cloud-header.png');
background-repeat: no-repeat;
background-position: center;
width: 100%;
height: 55vw;
background-size: cover;


}

.hero-text h1{
    font-weight: 300 bold;
    font-size: 4vw;
    color: black;
}


.hero-text h6{
    font-weight: 500;
    font-size: 1.8vw;
    color: rgb(91,91,92);
}

.buttons{
    text-align:center;
}

a.button1{
display:inline-block;
border:0.1em solid rgb(137,7,186);
background-color: white;
margin:0 2em 2em 0;
border-radius:0.12em;
box-sizing: border-box;
text-decoration:none;
font-family:'Roboto',sans-serif;
font-weight:300;
color: black;
text-align:center;
transition: all 0.2s;
}
a.button1:hover{
color:#000000;
background-color: transparent;
}

.large{
    padding:0.35em 1.2em;
    font-size: 1.6vw;
}

.small{
    padding: 0.28em 0.96em;
    font-size: 0.8vw;
}

.events-section{
    float: left;
    width: 50%;
}

.events-section h1{
    text-align: center;
}


.pdf-section{
    float: left;
    width: 50%;

}

.pdf-section h1{
    text-align: center;
}

.pdf-section img{
   width: 10%;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    float: left;
}

.item{
    margin-top: 30px;
    width: 100%;
}

h1{
    margin-top: 50px;
    font-family: "Arial Black", Gadget, sans-serif;
}

p{
    font-family: Tahoma, Geneva, sans-serif;
}

hr{
    background-color: black;
    height: 5px !important;
    width: 90%;

}

