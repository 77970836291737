.nav-color{
    background-color: lavender;
 /* background: #FFEFBA;  fallback for old browsers */
/* background: -webkit-linear-gradient(to bottom, #FFFFFF,  #FFEFBA);  Chrome 10-25, Safari 5.1-6 */
/* background: linear-gradient(to bottom, #FFFFFF, #FFEFBA); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */


}

.brand-style{
    color: white;
}

.nav-buttons{
   
    background-color: purple;
    color: white;


}

.nav-buttons:hover{
    background-color: white;
}

.menu-link {
    color: black;
    font-size: 18px;
    margin: 0 0.5rem;
    position: relative;
    font-weight: bold;
    transition: 0.25s ease-in-out
}

/* .menu-link::before {
    content: '';
    position: absolute;
    background-color: rgba(91, 28, 119, 0.25);
    bottom: 10%;
    left: 0;
    width: 0%;
    height: 80%;
    z-index: 1;
    transition: 0.25s ease-in-out
} */

.menu-link:hover{
    text-decoration: none;
    color: black;
    background-color: rgba(91, 28, 119, 0.25);
}

/* .menu-link:hover::before {
    width: 100%;
} */