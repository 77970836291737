@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Lora:wght@400;500;600&family=Open+Sans:wght@300;400&display=swap');

:root {
    --mh: #ff7373;
    --mh-light: #ffd9d9;
    --wlb: #5B1C77;
    --wlb-light: lavender;
    --o: #F39C12;
    --o-light: #FDEBD0;
    --sc: #3498DB;
    --sc-light: #D6EAF8;
    --aca: #1ABC9C;
    --aca-light: #dcfff8;
    --all: #424242;
    --all-light: #F2F3F4;
}

.mental-health-bg {background-color: var(--mh);}
.work-life-balance-bg {background-color: var(--wlb);}
.self-care-bg {background-color: var(--sc);}
.organization-bg {background-color: var(--o);}
.academia-bg {background-color: var(--aca)}

.blog-post-container, .blog-post-hub {
    width: 85%;
    padding: 2.5rem;
    margin: 2.5rem auto;
    font-family: 'Open Sans', sans-serif;
    /* font-weight: 300; */
}

.blog-post-container p, .blog-post-container li p:first-of-type::first-line{
    font-family: inherit;
    font-weight: 400;
    font-size: 3vh;
    margin: 2rem 0;
    text-indent: 8vh;
    background-color: transparent;
}

.blog-post-container p:first-of-type::first-line {
    /* font-family: 'Lora', serif;
    font-weight: 400;
    font-size: 25.5px;
    background-color: lavender; */
}

.blog-post-container h3 {
    font-family: 'Lora', serif;
    margin-bottom: 2rem;
}

.highlight-list {
    list-style: none;
    counter-reset: highlight-counter;
}

.highlight-list li {
    counter-increment: highlight-counter;
}

.highlight-list li strong, .highlight-list li::before{
    font-size: 3.5vh;
    content: counter(highlight-counter) ". ";
    background-color: var(--wlb-light);
    padding: 0.5rem 0.75rem 0.5rem 0.25rem;
    font-weight: bold;
}

.highlight-list li::before {
    padding: 0.5rem 0 0.5rem 0.75rem;
}

/* .blog-post-container ol li::before {
    font-weight: 700;
    font-size: 7vh;
} */

.blog-post-container blockquote {
    width: 100%;
    padding: 2.5rem;
    border-radius: 1.5rem;
    background-color: lavender;
    font-family: 'Lora', serif;
    font-size: 30px;
}

.blog-post-container blockquote::before {
    display: inline-block;
    width: 35px;
    height: 35px;
    margin-right: 20px;
    content: "";
    background: url('./quote.png') no-repeat 0 0;
    background-size: 100%;
}

.blog-title {
    width: 100%;
    /* background-color: #5B1C77; */
    color: #5B1C77;
    font-family: 'Lora', serif;
    /* padding: 1.5rem 2.5rem; */
}

.blog-title h2 {
    font-weight: 600;
    font-size: 50px;
}

.blog-title h6 {
    display: inline-block;
    margin: 0 2.5rem 0 0;
}

.blog-title .date{
    text-decoration: underline;
}

.blog-title .category {
    font-style: italic;
}

.blog-image {
    width: 75%;
    margin: 2.5rem auto;
}

.blog-image .col, .blog-image .col-md-6{
    max-height: 500px;
    overflow: hidden;
}

.blog-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-image p, .blog-image p:first-of-type::first-line {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    background-color: transparent;
    text-indent: 0px;
    margin: 0.5rem 0;
    text-align: center;
    font-style: italic;
}

.blog-image p:first-of-type {
    margin-top: 1.5rem;
}

.blog-post {
    display: block;
    width: 75%;
    /* max-height: 200px; */
    margin: 2.5rem 0;
    padding: 1.5rem;
    border: none !important;
    position: relative;
    transition: 0.25s ease-in;
}

.blog-post span {
    position: absolute;
    display: block;
}

span.top, span.bot {
    width: 0;
    height: 3px;
}

span.right, span.left {
    width: 3px;
    height: 0;
}

span.top    {top: 0;    left: 0;} 
span.right  {top: 0;    right: 0;}
span.bot    {bottom: 0; right: 0;}
span.left   {bottom: 0; left: 0;} 

@keyframes borderTop {
    0%                  { width: 0; }
    25%, 50%, 75%, 100% { width: 100%; }
}
@keyframes borderRight {
    0%, 25%         { height: 0; }
    50%, 75%, 100%  { height: 100%; }
}
@keyframes borderBottom {
    0%, 25%, 50%    { width: 0; }
    75%, 100%       { width: 100%; }
}
@keyframes borderLeft {
    0%, 25%, 50%, 75% { height: 0; }
    100%              { height: 100%; }
}
.blog-post:hover span.top    { animation: borderTop    1s; width:  100%; }
.blog-post:hover span.right  { animation: borderRight  1s; height: 100%; }
.blog-post:hover span.bot { animation: borderBottom 1s; width:  100%; }
.blog-post:hover span.left   { animation: borderLeft   1s; height: 100%; }

.blog-post:hover {
    text-decoration: none;
    color: black;
}

.blog-post h3 {
    font-family: 'Lora', serif;
}

.blog-post p {
    text-transform: capitalize;
    margin: 0.5rem 0;
}
.blog-post img {
    width: 75%;
    object-fit: cover;
}

.blog-cat {
    min-width: 100px;
    padding: 0.5rem 0.75rem;
    margin: 0 0.5rem 0.5rem 0;
    text-transform: capitalize;
    transition: 0.15s ease-in-out;
}

.blog-cat:focus {
    outline: none;
}

.all {
    border: 1px solid var(--all);
    color: var(--all);
}

.all:hover {
    color: var(--all-light);
    background-color: var(--all);
}

.mh, .mental-health {
    border: 1px solid var(--mh);
    color: var(--mh);
    background-color: var(--mh-light);
}

.mh:hover {
    color: var(--mh-light);
    background-color: var(--mh);
}

.wlb, .work-life-balance {
    border: 1px solid var(--wlb);
    color: var(--wlb);
    background-color: var(--wlb-light);
}

.wlb:hover {
    color: var(--wlb-light);
    background-color: var(--wlb);
}

.sc, .self-care {
    border: 1px solid var(--sc);
    color: var(--sc);
    background-color: var(--sc-light);
}

.sc:hover {
    color: var(--sc-light);
    background-color: var(--sc);
}

.o, .organization{
    border: 1px solid var(--o);
    color: var(--o);
    background-color: var(--o-light);
}

.o:hover {
    color: var(--o-light);
    background-color: var(--o);
}

.aca, .academia{
    border: 1px solid var(--aca);
    color: var(--aca);
    background-color: var(--aca-light);
}

.aca:hover {
    color: var(--aca-light);
    background-color: var(--aca);
}

@media(max-width: 675px) {
    .blog-post, .blog-post-hub, .blog-post-container, .blog-image, .blog-post img {
        width: 100%;
    }
}

@media(max-width: 480px) {
    .blog-post-container p, .blog-post-container blockquote {
        font-size: 18px;
    }

    .blog-post {
        max-height: unset;
    }
}